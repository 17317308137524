<template>
  <div>
    <div
      class="text-detail"
      v-show="TextDetail && Object.keys(TextDetail).length"
    >
      <TextMaterialsSlider
        v-if="banners && banners.length"
        :banners="banners"
        :title="TextDetail.name"
        :color="'#fff'"
      />
      <div class="content" v-else>
        <div class="text-detail__title" v-html="TextDetail.name"></div>
      </div>
      <Breadcrumbs
        :pages="[
          { name: 'Библиотека', link: { name: 'Library' } },
          {
            name: 'Публикации',
            link: {
              name: 'TextMaterials',
            },
          },
          {
            name: TextDetail.name,
          },
        ]"
      />
      <div class="content">
        <div class="text-detail__row mt-4">
          <div>
            <div class="material__tags-wrap">
              <div class="material__tags">
                <div class="material__tag" v-if="TextDetail.type">
                  {{ TextDetail.type.name }}
                </div>
                <div
                  class="material__tag"
                  v-for="nosology in TextDetail.nosology"
                  :key="`nos${nosology.id}`"
                  v-html="nosology.title"
                ></div>
                <div
                  class="material__tag"
                  v-for="disease in TextDetail.disease"
                  :key="`dis${disease.id}`"
                  v-html="disease.title"
                ></div>
                <div
                  class="material__tag material__tag_new"
                  v-if="TextDetail.is_new && false"
                >
                  Новинка
                </div>
              </div>
              <Favorite
                class="large"
                v-if="!$route.query.access"
                :watchLater="TextDetail.watch_later"
                :favorite="TextDetail.favorite"
                @watchLater="
                  watchLater(
                    TextDetail.watch_later ? 'DELETE' : 'POST',
                    TextDetail.slug,
                    null,
                    true
                  )
                "
                @favorite="
                  favorite(
                    TextDetail.favorite ? 'DELETE' : 'POST',
                    TextDetail.slug,
                    null,
                    true
                  )
                "
              />
            </div>

            <div class="material__pdf-preview" v-if="TextDetail.file">
              <img :src="TextDetail.pdf_preview" />
              <a
                :href="TextDetail.file.url"
                target="_blank"
                class="button button_pink px-6 mx-auto mb-4"
              >
                Скачать
                <svg
                  class="ml-2"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"
                    fill="currentColor"
                  ></path>
                </svg>
              </a>
            </div>

            <TextDetailBody
              v-if="
                TextDetail.content &&
                TextDetail.presentations &&
                !TextDetail.presentations.length
              "
              :content="TextDetail.content"
            />
            <div
              class="text-detail__body"
              v-if="TextDetail.presentations && TextDetail.presentations.length"
            >
              <Presentations
                :color="'#830051'"
                :autoplay="'false'"
                :Slides="presentation.pages"
                v-for="(presentation, ind) in TextDetail.presentations"
                :key="ind"
              />
            </div>
          </div>

          <div class="sticky">
            <RightAside
              v-if="asideItems && asideItems.length"
              :items="asideItems"
              @medicationsClick="medicationsClick"
              @calendarClick="calendarClick"
              @nosologyClick="nosologyClick"
            />
          </div>
        </div>
        <div
          v-if="TextDetail.survey && filteredSurveyQuestions.questions.length"
          class="button button_pink mt-8"
          @click="openSurvey"
        >
          Пройти опрос
        </div>
        <div class="text-detail__buttons">
          <router-link
            :to="{ name: 'TextMaterials' }"
            class="text-detail__load button button_pink"
          >
            <span class="mr-2 mt-n-1">
              <svg
                width="11"
                height="18"
                viewBox="0 0 11 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 1.5L1.5 9L9 16.5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </span>
            Назад
          </router-link>
          <div
            class="text-detail__up button button_empty-pink"
            @click="toTop()"
          >
            К началу страницы
            <span class="ml-2">
              <svg
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.25 8.75012L8.75 1.25012L16.25 8.75012"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <LibrarySlider
        v-if="
          TextDetail.nosology &&
          TextDetail.nosology.length &&
          sliderItems.length
        "
        @clickMaterials="clickMaterials"
        @toNosol="toNosol"
        :nosology="TextDetail.nosology[0]"
        :items="sliderItems"
        :page="'TextDetail'"
      />
      <PopUpDefault
        @close="closeSurvey"
        v-if="survey.opened && filteredSurveyQuestions.questions.length"
        class="profiling-modal__survey"
      >
        <LibrarySurvey
          @close="closeSurvey"
          @closeAfterSend="closeAfterSend"
          :content_type="'text'"
          :material_slug="TextDetail.slug"
          :survey="filteredSurveyQuestions"
        />
      </PopUpDefault>
    </div>
    <div v-if="TextDetail === 404"><Choch class="inPage" /></div>
    <div v-if="TextDetail === false"><Error class="inPage" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import "@/assets/scss/detail_content.scss";
import "@/assets/scss/clinical_case.scss";
import RightAside from "../../components/pageComponents/RightAside.vue";
import TextDetailBody from "../../components/pageComponents/TextDetailBody.vue";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";
import Favorite from "../../components/pageComponents/Favorite.vue";
import Presentations from "../../components/pageComponents/Presentations.vue";
import TextMaterialsSlider from "@/components/main/TextMaterialsSlider.vue";

import LibrarySurvey from "@/components/pageComponents/LibrarySurveyModal.vue";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
import { bus } from "@/main";

export default {
  metaInfo() {
    if (this.TextDetail.name) {
      return { title: this.TextDetail.name.replace(/<[^>]*>?/gm, "") };
    } else {
      return { title: this.TextDetail.name };
    }
  },
  name: "TextDetail",
  components: {
    Breadcrumbs,
    Choch,
    RightAside,
    LibrarySlider,
    Error,
    TextDetailBody,
    Favorite,
    Presentations,
    TextMaterialsSlider,
    LibrarySurvey,
    PopUpDefault,
  },
  data: () => ({
    items: [],
    index: null,
    survey: {
      opened: false,
      survey: null,
    },
  }),
  computed: {
    ...mapGetters(["TextDetail", "TextList"]),
    filteredSurveyQuestions() {
      return {
        ...this.TextDetail.survey,
        questions: [
          ...this.TextDetail.survey.questions.filter((q) => !q.is_completed),
        ],
      };
    },
    sliderItems() {
      return this.TextList
        ? this.TextList.filter((el) => el.slug !== this.TextDetail.slug)
        : [];
    },
    filter() {
      if (this.TextDetail && Object.keys(this.TextDetail).length) {
        return {
          therapeutic_areas: [this.TextDetail.nosology[0]?.slug],
        };
      } else {
        return {};
      }
    },
    banners() {
      let arr = [];
      if (
        this.TextDetail.banner_desktop ||
        this.TextDetail.banner_tablet ||
        this.TextDetail.banner_mobile
      ) {
        arr.push({
          image_desktop: this.TextDetail.banner_desktop,
          image_tablet: this.TextDetail.banner_tablet,
          image_mobile: this.TextDetail.banner_mobile,
        });
      }
      return arr;
    },
    asideItems() {
      if (this.TextDetail && Object.keys(this.TextDetail).length) {
        return [
          {
            title: "Препараты АЗ",
            // descr: this.TextDetail.nosology.map((el) => el.title).join(","),
            image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-1.jpg`,
            image_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-1_small.jpg`,
            theme: "white",
            event: "medicationsClick",
            to: {
              name: "Medications",
              query: {
                therapeutic_areas: this.TextDetail.nosology
                  .map((el) => el.slug)
                  .join(","),
                disease: this.TextDetail.disease.map((el) => el.slug).join(","),
              },
            },
          },
          {
            title: "Календарь мероприятий",
            image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-2.jpg`,
            image_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-2_small.jpg`,
            links: [
              {
                event: "calendarClick",
                title: "Предстоящие",
                to: {
                  name: "Events",
                  query: {
                    therapeutic_areas: this.TextDetail.nosology
                      .map((el) => el.slug)
                      .join(","),
                  },
                },
              },
              {
                event: "calendarClick",
                title: "Архив",
                to: {
                  name: "ArchiveEvents",
                  query: {
                    therapeutic_areas: this.TextDetail.nosology
                      .map((el) => el.slug)
                      .join(","),
                    disease: this.TextDetail.disease
                      .map((el) => el.slug)
                      .join(","),
                  },
                },
              },
            ],
          },
          {
            title: "Терапевтические <br>области",
            image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-4.jpg`,
            image_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-4_small.jpg`,
            event: "nosologyClick",
            to: { name: "Nosology" },
          },
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchTextDetail",
      "fetchTextMaterials",
      "fetchTextWatchLater",
      "fetchTextFavorite",
      "fetchTextHistory",
    ]),
    closeSurvey() {
      this.$set(this.survey, "opened", false);
      bus.$emit("scrollLock", false);
    },
    openSurvey() {
      this.$set(this.survey, "opened", true);
    },
    async closeAfterSend() {
      bus.$emit("scrollLock", false);
      if (!this.$route.query.access) {
        await this.fetchTextDetail({ slug: this.$route.params.slug }).then(
          () => {
            if (this.TextDetail.survey) {
              this.$set(this.survey, "survey", this.TextDetail.survey);
            }
          }
        );
      } else {
        this.$set(this.survey, "opened", false);
      }
    },
    toTop() {
      document.body.scrollIntoView({ behavior: "smooth" });
    },
    medicationsClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "textmaterial page medications click", {
          "textmaterial page medications click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    calendarClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "textmaterial  page events click", {
          "textmaterial  page events click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    nosologyClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "textmaterial page therapeutic areas click", {
          "textmaterial page therapeutic areas click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    clickMaterials(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "textmaterial page more materials click", {
          "textmaterial page more materials click": {
            "textmaterial page name": name,
            ...this.$root.ymFields,
          },
        });
      }
    },
    async watchLater(method, slug, filter, favoritePage) {
      await this.fetchTextWatchLater({
        method: method,
        slug: slug,
        filter: filter,
        favoritePage: favoritePage,
      });
      await this.fetchTextDetail({
        slug: this.$route.params.slug,
        access: this.$route.query.access || null,
      });
    },
    async favorite(method, slug, filter, favoritePage) {
      await this.fetchTextFavorite({
        method: method,
        slug: slug,
        filter: filter,
        favoritePage: favoritePage,
      });
      await this.fetchTextDetail({
        slug: this.$route.params.slug,
        access: this.$route.query.access || null,
      });
    },
    toNosol(title, to) {
      const vm = this;

      ym(
        91468266,
        "reachGoal",
        "textmaterial page current therapeutic areas click",
        {
          "textmaterial page current therapeutic areas click": {
            "therapeutic area": title,
            ...this.$root.ymFields,
          },
        }
      );
      this.$router.push(to).catch(() => {});
    },
  },
  async mounted() {
    const vm = this;
    if (
      this.TextDetail.file &&
      Object.keys(this.TextDetail.file).length &&
      !this.TextDetail.content
    ) {
      window.open(`${this.TextDetail.file.url}`, "_self");
    }
  },
  watch: {
    "$route.params.slug": {
      handler: async function () {
        await this.fetchTextDetail({
          slug: this.$route.params.slug,
          access: this.$route.query.access || null,
        });
        if (!this.$route.query.access) {
          await this.fetchTextHistory(this.$route.params.slug);
        }
      },
      deep: true,
      immediate: true,
    },
    TextDetail: {
      handler: async function () {
        if (
          // !this.TextList.length &&
          this.TextDetail &&
          Object.keys(this.TextDetail).length
        ) {
          await this.fetchTextMaterials(this.filter);
        }
        if (
          this.TextDetail.file &&
          Object.keys(this.TextDetail.file).length &&
          !this.TextDetail.content
        ) {
          window.open(`${this.TextDetail.file.url}`, "_self");
        }
        localStorage.setItem("textBlock", this.TextDetail.slug);

        if (this.TextDetail.survey) {
          this.$set(this.survey, "survey", this.TextDetail.survey);
          this.$set(this.survey, "opened", true);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.text-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 280px;
    column-gap: 136px;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }

  &__body {
    margin-top: 64px;
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1220px) {
      margin-bottom: 80px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__load {
    width: 110px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__up {
    margin-left: auto;
    width: 213px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}

.sticky {
  @media screen and (min-width: 1221px) {
    position: sticky;
    top: 115px;
  }
}

.material {
  &__tags-wrap {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }
  &__tags {
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 1220px) {
      margin-bottom: 16px;
    }
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__tag {
    padding-left: 10px;
    position: relative;
    margin-right: 8px;
    // margin-bottom: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
    white-space: nowrap;
    // max-width: calc(50% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;

    @media screen and (max-width: 767px) {
      margin-bottom: 8px;
    }

    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: #3c4242;
      border-radius: 50%;
      display: block;
      content: "";
    }

    &:last-child {
      margin-right: 0;
    }

    &_new {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 4;
      display: inline-block;
      padding: 4px 8px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #d8dada;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #1f1f1f;
    }
  }

  &__pdf-preview {
    position: relative;
    max-width: 400px;
    height: 600px;
    display: flex;
    flex-direction: column;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      content: "";
      background-color: rgba(0, 0, 0, 0.5);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) -25%,
        rgba(0, 0, 0, 0) 25%
      );
    }

    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    & .button {
      position: relative;
      z-index: 2;
      margin-top: auto;
    }
  }
}

.favorite {
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 16px;
  }
}
</style>
